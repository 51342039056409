import { Box } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import MobileSplash from "../components/MobileSplash";
import DesktopSplash from "../components/DesktopSplash";
import imgLogo from "../images/logo.svg";
import DesktopBanners from "../components/DesktopBanners";

const HomePage = () => {
  return (
    <Box
      sx={{
        oveflow: "hidden",
        height: "100%",
        position: "relative",
        maxWidth: 1920,
        minHeight: 900,
        marginX: "auto",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", md: 800 },
          minWidth: { xs: "100%", md: 800 },
          padding: { xs: "36px 0 0 16px", md: "56px 0 0 64px" },
        }}
      >
        <motion.div
          initial={{ left: "-20px", position: "relative", opacity: 0 }}
          animate={{ left: 0, opacity: 1 }}
          transition={{ duration: 0.25, ease: "easeInOut" }}
        >
          <Box component="img" src={imgLogo} alt="logo" sx={{ position: "relative", height: { xs: 24, md: 32 } }} />
        </motion.div>
        <MobileSplash />
        <DesktopSplash />
      </Box>
      <Box sx={{ position: "relative", width: "100%" }}>
        <DesktopBanners />
      </Box>
    </Box>
  );
};

export default HomePage;
